//Mobile------------------------------------------------------------------------------------------------------
.hero {
	.img-wrapper {
		position: relative;
		padding-top: 50%;
		img {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
		&:after {
			content: '';
			display: block;
			position: absolute;
			inset: 0;
			background: linear-gradient(173deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.hero {
		.img-wrapper {
			padding-top: 0;
			height: 425px;
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}