//Mobile------------------------------------------------------------------------------------------------------
.header {
	border-bottom: 2px solid var(--color-accent);
	z-index: 10;
	position: relative;
	.top-button {
		background-color: var(--color-accent);
		text-transform: uppercase;
		color: var(--color-accent-hover);
		line-height: 1em;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		transition: all 0.3s;
		font-weight: 300;
	}
	.logo {
		width: 250px;
	}
	nav {
		ul {
			font-size: 14px;
			text-transform: uppercase;
			
		}
		a {
			color: var(--color-primary);
			display: block;
			position: relative;
			transition: all 0.3s;
			&.active {
				color: var(--color-accent);
				font-weight: 500;
			}
			
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}
@include media-breakpoint-down(lg) {
	.header {
		
		nav {
			opacity: 0;
			transition: all 0.3s;
			pointer-events: none;
			position: fixed;
			inset: 0;
			height: 100%;
			background-color: rgba(0,0,0,0.8);
			li {
				margin-bottom: 12px;
				font-size: 18px;
				font-weight: 500;
			}
		}
		.nav-wrapper {
			background-color: #FFF;
			height: 100%;
			padding: 24px;
			width: calc(100% - 48px);
			transform: translateX(-100%);
			transition: all 0.3s;
		}
		.navbar-closer {
			position: fixed;
			right: 0;
			top: 0;
			padding: 8px;
			.icon {
				fill: #FFF;
				width: 32px;
				height: 32px;
			}
		}
		.navbar-toggler {
			padding: 8px;
			margin-top: 8px;
			.icon {
				fill:var(--color-secondary);
				width: 32px;
				height: 32px;
			}
		}
		nav {
			&.show {
				opacity: 1;
                pointer-events: auto;
				.nav-wrapper {
					transform: translateX(0);
				}
			}
		}
	}
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.header {
		.logo {
			width: 440px;
			margin-right: 24px;
		}
		nav {
			margin-top: 16px;
			ul {
				border-bottom: 2px solid var(--color-accent-hover);
				padding-bottom: 6px;
			}
			li {
				padding: 0 8px;
				line-height: 1em;
				&:not(:last-child) {
					border-right: 1px solid #8c8c85;
				}
				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
				}
				
			}
			a {
				&:after {
					content : "";
					display: block;
					width: 100%;
					height: 2px;
					background-color: var(--color-accent);
					position: absolute;
					bottom: -8px;
					transition: all 0.3s;
					transform: scaleX(0);
				}
				&.active {
					&:after {
						transform: scaleX(1);
					}
				}
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	
}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
	.header {
		.top-button:hover {
			background-color: var(--color-accent-hover);
			color: var(--color-accent);
		}
		nav {
			a:hover:after {
				transform: scaleX(1);
			}
		}
	}
}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}