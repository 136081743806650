//Mobile------------------------------------------------------------------------------------------------------
.img-bg {
	text-align: center;
	.text-content {
		position: relative;
		z-index: 1;
	}
	.inner {
		position: relative;
		&:before {
			content:"";
			display: block;
			position: absolute;
			inset: 0;
			background-color: rgba(0,0,0,0.55);
			z-index: 1;
		}
		img {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	
	h2 {
		border-bottom: 2px solid var(--color-accent);
		color: var(--color-accent);
		font-weight: 400;
		text-transform: uppercase;
		position: relative;
	}
	p {
		color: #FFF;
		position: relative;
	}
	.button {
		position: relative;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}