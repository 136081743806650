/* roboto-300 - latin */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	font-display: swap; // font-display: optional (da testare)
	src: local(""), url("../fonts/roboto-v30-latin-300.woff") format("woff"); /* Modern Browsers */
}
/* roboto-regular - latin */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-display: swap; // font-display: optional (da testare)
	src: local(""), url("../fonts/roboto-v30-latin-regular.woff") format("woff"); /* Modern Browsers */
}
/* roboto-500 - latin */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-display: swap; // font-display: optional (da testare)
	src: local(""), url("../fonts/roboto-v30-latin-500.woff") format("woff"); /* Modern Browsers */
}
/* roboto-700 - latin */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-display: swap; // font-display: optional (da testare)
	src: local(""), url("../fonts/roboto-v30-latin-700.woff") format("woff"); /* Modern Browsers */
}
