//Mobile------------------------------------------------------------------------------------------------------
.editorial-content {
	font-size: 14px;
	h2 {
		border-bottom: 2px solid var(--color-accent);
		color: var(--color-primary);
		font-size: 26px;
	}
	.separator {
		border-bottom: 2px solid var(--color-accent);
	}
	ul  ul {
		list-style-type: none;
		padding-left: 0;
		li:before {
			content: "-";
			display:inline-block;
		    margin-right: 6px;
		}
	}
	ul {
		padding-left: 1rem;
	}
	p {
		font-weight: 400;
		strong {
			color: var(--color-primary);
		}
		span {
			font-weight: 600;
		}
	}
	.mille {
		max-width: 100%;
		margin: auto;
	}
	h3 {
		font-size: 14px;
		font-weight: 600;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}