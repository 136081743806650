//Mobile------------------------------------------------------------------------------------------------------
.footer {
	border-top: 2px solid var(--color-accent);
	font-size: 14px;
	.footer-inner {
		background-color: #e8e8e7;
	}
	.logo-footer {
		width: 200px;
	}
	p,ul {
		line-height: 2em;
	}
	ul {
		a {
			color: var(--text-color);
			transition: all 0.3s;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.footer {
		.logo-footer {
			width: 100%;
		}
		.col-lg-3:not(:last-child) {
			border-right: 2px solid var(--color-accent);
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
	footer {
		ul {
			a:hover {
				color: var(--color-secondary);
				font-weight: 600;
			}
		}
	}
}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}