//Mobile------------------------------------------------------------------------------------------------------
body {
	background-color: var(--body-bg);
	color: var(--body-color);
}

a {
	color: var(--link-color);
	text-decoration: none;
}

button {
	margin: 0;
	padding: 0;
	background-color: transparent;
	border: none;
}
.fs-24 {
	font-size: 24px !important;
}
.list-inline {
	padding: 0;
	margin: 0;
	list-style-type: none;
}
.link-default {
	color: var(--color-accent);
	font-weight: 600;
	transition: all 0.3s;
}
.line-normal {
	line-height: 1.2 !important;
}
.line-top {
	border-top:2px solid var(--color-accent)
}
h2 {
	font-size: 26px;
}
p {
	font-size: 16px;
}
h1 {
	font-size: 26px;
}
.no-border {
	border: none !important;
}
.privacy-h2 {
	font-size: 20px !important;
}
.text-accent {
	color: var(--color-accent);
}
a.text-accent {
	transition: all 0.3s;
}
a.text-accent:hover {
	color: var(--color-secondary);
}

//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
}

//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
}

//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
}

//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
}

//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
	button,
	input[type="checkbox"] + label,
	input[type="radio"] + label {
		&:hover {
			cursor: pointer;
		}
	}
	.link-default:hover {
		color: var(--color-secondary);
	} 
}

//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
}
