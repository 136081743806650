//Mobile------------------------------------------------------------------------------------------------------
.banner {
	.img-wrapper {
		width: 310px;
		height: 270px;
		border-radius: 16px;
		overflow: hidden;
		position: relative;
		flex-shrink: 0;
		img {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.text {
		background-color: var(--color-accent);
		color: #FFF;
		border-radius: 16px;
	}
	h2 {
		border-bottom: 2px solid #FFF;
		text-transform: uppercase;
	}
	.button-wrapper {
		margin-bottom: -52px;
	}

}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.banner {
		
		.text {
			border-top-left-radius:0;
			border-bottom-left-radius:0;
		}
	}
}

@include media-breakpoint-down(lg) {
	.banner {
		text-align: center;
	}
}
//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}