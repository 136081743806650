:root {
	//-colors
	--color-primary: #0050aa;
	--color-accent: #78be00;
	--color-primary-hover: var(--color-accent);
	--color-accent-hover: #d6ebb2;
	--color-secondary: #00812f;
	--color-secondary-hover: #5f2b00;
	--text-color: #66665c;
	--border-color: #aaa;
	--border-color-light: #eee;
	--border-color-lighter: #fafafa;
	//-general
	--body-bg: #fff;
	--body-color: var(--text-color);
	--link-color: var(--color-primary);
	--border-radius: 8px;
	//-fonts
	--font-primary:"urw-din", sans-serif;
	--font-secondary: "urw-din-semi-condensed", sans-serif;
	--font-tertiary :  "urw-din-condensed", sans-serif;
	//-icon
	--icon-size-sm: 16px;
	--icon-size: 24px;
	--icon-size-lg: 32px;
	--icon-size-xl: 40px;
	//buttons
	// focus outline
	--outline-color: orange;
	--outline-width: 2px;
	
	//back to top
	--backtotop-zindex: 10;
	--backtotop-size-sm: 50px;
	--backtotop-size-lg: 60px;
	--backtotop-position-right-sm: 25px;
	--backtotop-position-btm-sm: 40px;
	--backtotop-position-right-lg: 25px;
	--backtotop-position-btm-lg: 40px;
	--backtotop-radius: 8px;
	--backtotop-bg-color: var(--color-accent);
	--backtotop-border: none;
	--backtotop-icon-color: #fff;
	
}

//dark theme
@media (prefers-color-scheme: dark) {
	:root {
		//-general
	}
}
