//Mobile------------------------------------------------------------------------------------------------------
.button {
	display: inline-flex;
	font-size: 16px;
	padding: 11px 22px;
	border-radius: 8px;
	&.outline {
		border: 1px solid var(--color-accent);
		color: var(--color-accent);
		text-transform: uppercase;
		transition: all 0.3s;
		&.text-darker {
			background-color: #FFF;
			color: var(--color-secondary);
			font-size: 22px;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
}

//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
}

//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
}

//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
}

//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
	.button {
		&.outline:hover {
			background-color: var(--color-accent);
			color: #FFF;
		}
		&.outline.text-darker:hover {
            background-color: var(--color-secondary);
            color: #FFF;
			
        }
	}
}

//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
	
}
