//Mobile------------------------------------------------------------------------------------------------------
.page-title {
	border-bottom: 2px solid var(--color-accent);
	color: var(--color-primary);
	ul {
		font-size: 12px;
		li {
			color: var(--color-accent);
			text-transform: uppercase;
			a {
				color: #94948d;
				transition: all 0.3s;
				&:hover {
					color:var(--color-secondary)
				}
			}
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}